// src/components/Navbar.js

import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <nav>
      <div className="logo">AraucariaTech</div>
      <ul className="nav-links">
        <li><a href="#hero">Inicio</a></li>
        <li><a href="#overview">Acerca de Nosotros</a></li>
        <li><a href="#services">Servicios</a></li>
        <li><a href="#how-it-works">Preguntas Frecuentes</a></li>
        <li><a href="#why-choose-us">¿Por Qué Nosotros?</a></li>
        <li><a href="#testimonials">Testimonios</a></li>
        <li><a href="#contact">Contacto</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
