// src/components/Services.js
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';
import './Services.css';

function Services() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="services">
      <h2>Nuestros Servicios</h2>
      <Slider {...settings} className="service-list">
        <div className="service-item">
          <h3>Gestión de Proyectos</h3>
          <p>Administramos el proceso de desarrollo completo, liberando tu tiempo</p>
        </div>
        <div className="service-item">
          <h3>Soluciones de Desarrollo</h3>
          <ul className="no-bullets">
            <li>Web</li>
            <li>Apps Móbiles</li>
            <li>Software personalizado</li>
            <li>Apps de Escritorio</li>
          </ul>
        </div>
        <div className="service-item">
          <h3>Infraestructura Digital</h3>
          <ul className="no-bullets">
            <li>Hosting</li>
            <li>Servicio de Correo Institucional</li>
            <li>Mantenimiento y Soporte</li>
          </ul>
        </div>
      </Slider>
    </section>
  );
}

export default Services;
