// src/components/Hero.js

import React from 'react';
import './Hero.css';

function Hero() {
  return (
    <section id="hero">
      <div className="hero-content">
        <h1>Tu pones las ideas. Nosotros el trabajo.</h1>
        <p>Nivelemos la cancha tecnológica</p>
        <a href="#contact" className="cta-button">Digitalizate Hoy</a>
      </div>
    </section>
  );
}

export default Hero;
