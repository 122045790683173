// src/components/Overview.js

import React from 'react';
import './Overview.css';

function Overview() {
  return (
    <section id="overview">
      <div className="overview-container">
        <div className="data-box">
          <p>
            <strong>44.9%</strong> de las grandes empresas y <strong>46.3%</strong> de las Pymes sin iniciar su transformación digital.
          </p>
        </div>
        <div className="data-box">
          <p>
            Se necesitarán <strong>700 mil programadores</strong> para 2025.
          </p>
        </div>
        <div className="data-box">
          <p>
            En 2020, solo el <strong>31%</strong> de los empleos de DevOps encontraron candidatos adecuados.
          </p>
        </div>
        <div className="data-box">
          <p>
            La dificultad para encontrar mano de obra calificada limita la transformación digital y el crecimiento. Tener procesos modernos y una presencia digital poderosa no es solo un privilegio de las grandes empresas.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Overview;

